var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-profile","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-store-settings","title":"Tienda","color":"orange"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.businessParentsChilds.filter(function (item) { return item.status === 1; }),"loading":_vm.fetchingParentChilds,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
            'items-per-page-text': 'Filas por página',
            pageText: '{0}-{1} de {2}'
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('store-table-top')]},proxy:true},{key:"item.phone2",fn:function(ref){
          var business = ref.item;
return [(business.phone2)?_c('span',[_vm._v(_vm._s(business.phone2))]):_c('span',[_vm._v("No hay teléfono secundario disponible")])]}},{key:"item.actions",fn:function(ref){
          var business = ref.item;
return [_c('store-table-actions',{attrs:{"business":business}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }