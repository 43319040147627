<template>
  <div>
    <v-card-text>
      <v-form v-model="isValidData">
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="data.code"
              type="text"
              :rules="requiredFieldRules('Correlativo es requerido')"
              outlined
              label="Correlativo"
              required
              @keyup.enter="submit"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="data.name"
              type="text"
              :rules="requiredFieldRules('Nombre es requerido')"
              outlined
              label="Nombre"
              required
              @keyup.enter="submit"
            />
          </v-col>

          <v-col
            md="2"
            class="mx-0"
          >
            <v-autocomplete
              v-model="rifType"
              :items="typesI"
              outlined
              required
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="data.rif"
              type="text"
              :rules="requiredFieldRules('Rif requerido')"
              outlined
              label="Rif"
              required
              @keyup.enter="submit"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="data.email"
              type="email"
              :rules="requiredFieldRules('Email requerido')"
              outlined
              label="Email"
              required
              @keyup.enter="submit"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="data.address"
              type="text"
              :rules="requiredFieldRules('Dirección requerida')"
              outlined
              label="Dirección"
              required
              @keyup.enter="submit"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <!-- <v-text-field
              v-model="data.phone1"
              type="number"
              :rules="requiredFieldRules('Teléfono requerida')"
              outlined
              label="Teléfono"
              required
              @keyup.enter="submit"
            /> -->

            <vue-tel-input-vuetify
              v-model="data.phone1"
              label="Teléfono 1"
              return-masked-value
              placeholder="0000-0000000"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <vue-tel-input-vuetify
              v-model="data.phone2"
              label="Teléfono 2"
              return-masked-value
              placeholder="0000-0000000"
              outlined
            />
            <!-- <v-text-field
              v-model="data.phone2"
              type="number"
              outlined
              label="Teléfono secundario"
              @keyup.enter="submit"
            /> -->
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        color="red"
        @click="$emit('close')"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="secondary"
        @click="submit"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'EditStoreForm',
    props: {
      business: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        isValidData: false,
        typesI: ['J'],
        rifType: 'J',
        data: {
          code: '',
          name: '',
          email: '',
          rif: '',
          address: '',
          phone1: '',
          phone2: '',
        },
      }
    },
    computed: {
      ...mapState([
        'user',
        'actionsParentBusiness',
      ]),
    },
    created () {
      this.fillDataFields()
    },
    methods: {
      ...mapActions([
        'updateBusiness',
        'getParentChilds',
      ]),
      requiredFieldRules (errorMessage) {
        return [
          v => !!v || errorMessage,
        ]
      },
      fillDataFields () {
        for (const [key, val] of Object.entries(this.business)) {
          if (key === 'rif') {
            const rif = this.business.rif.split('-')
            this.rifType = rif[0].length === 1 ? rif[0] : 'J'
            this.data.rif = rif[1]
          } else {
            this.data[key] = val
          }
        }
      },
      async submit () {
        if (this.user && this.isValidData) {
          // // // // // // // // // // // // // // // // // // // // // // // // // // console.log({
          //   id: this.business.id,
          //   data: {
          //     ...this.data,
          //     rif: `${this.rifType}-${this.data.rif}`,
          //   },
          // })
          await this.updateBusiness({
            id: this.business.id,
            data: {
              ...this.data,
              rif: `${this.rifType}-${this.data.rif}`,
            },
          })
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.user.businessId || this.actionsParentBusiness)
          this.$emit('close')
          await this.getParentChilds(this.user.businessId || this.actionsParentBusiness)
        }
      },
    },
  }
</script>
