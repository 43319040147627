<template>
  <div>
    <v-card-text>
      <v-container>
        <v-form v-model="isValidData">
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="data.code"
                type="text"
                :rules="requiredFieldRules('Correlativo es requerido')"
                outlined
                label="Correlativo"
                required
                @keyup.enter="submit"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="data.name"
                type="text"
                :rules="requiredFieldRules('Nombre es requerido')"
                outlined
                label="Nombre"
                required
                @keyup.enter="submit"
              />
            </v-col>
            <v-col
              sm="4"
              md="2"
              class="mx-0"
            >
              <v-autocomplete
                v-model="rifType"
                :items="typesI"
                outlined
                required
              />
            </v-col>
            <v-col
              cols="8"
              md="4"
            >
              <v-text-field
                v-model="data.rif"
                type="text"
                :rules="requiredFieldRules('Rif requerido')"
                outlined
                label="Rif"
                required
                @keyup.enter="submit"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="data.email"
                type="email"
                :rules="emailRules"
                outlined
                label="Email"
                required
                @keyup.enter="submit"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="data.address"
                type="text"
                :rules="requiredFieldRules('Dirección requerida')"
                outlined
                label="Dirección"
                required
                @keyup.enter="submit"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <!-- <v-text-field
                v-model="data.phone1"
                type="number"
                :rules="requiredFieldRules('Teléfono requerida')"
                outlined
                label="Teléfono"
                required
                @keyup.enter="submit"
              /> -->

              <vue-tel-input-vuetify
                v-model="data.phone1"
                label="Teléfono 1"
                return-masked-value
                placeholder="0000-0000000"
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <!-- <v-text-field
                v-model="data.phone2"
                type="number"
                outlined
                label="Teléfono secundario"
                @keyup.enter="submit"
              /> -->

              <vue-tel-input-vuetify
                v-model="data.phone2"
                label="Teléfono 2"
                return-masked-value
                placeholder="0000-0000000"
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="red"
        text
        :disabled="creatingBusiness"
        @click="$emit('close')"
      >
        Cerrar
      </v-btn>
      <v-btn
        color="secondary"
        :loading="creatingBusiness"
        @click="submit"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'CreateBusinessForm',
    data () {
      return {
        isValidData: false,
        typesI: ['J'],
        rifType: 'J',
        data: {
          code: '',
          name: '',
          email: '',
          rif: '',
          address: '',
          phone1: '',
          phone2: '',
        },
        emailRules: [
          v => !!v || 'El email es requerido',
          v => /.+@.+/.test(v) || 'Debe ingresar un email valido',
        ],
      }
    },
    computed: {
      ...mapState([
        'user',
        'creatingBusiness',
        'actionsParentBusiness',
      ]),
    },
    methods: {
      ...mapActions([
        'createBusiness',
        'getParentChilds',
      ]),
      requiredFieldRules (errorMessage) {
        return [
          v => !!v || errorMessage,
        ]
      },
      async reloadAllContent () {
        this.$emit('close')
        await this.getParentChilds(this.user.businessId || this.actionsParentBusiness)
      },
      async submit () {
        if (this.isValidData && this.user && !this.creatingBusiness) {
          await this.createBusiness({
            ...this.data,
            rif: `${this.rifType}-${this.data.rif}`,
            parentId: this.user.businessId || this.actionsParentBusiness,
            miscId: null,
          })
          this.reloadAllContent()
        }
      },
    },
  }
</script>
