<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
  >
    <v-card>
      <v-card-title>
        Crear tienda
      </v-card-title>
      <create-business-form @close="dialog = false" />
    </v-card>
  </v-dialog>
</template>

<script>
  import CreateBusinessForm from './CreateBusinessForm.vue'

  export default {
    name: 'StoreTableTopCreateBusiness',
    components: {
      CreateBusinessForm,
    },
    props: {
      value: {
        type: Boolean,
        default () {
          return false
        },
      },
    },
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        },
      },
    },
  }
</script>
