<template>
  <div>
    <store-table />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import StoreTable from '../components/admin-store/StoreTable.vue'

  export default {
    name: 'AdminStore',
    components: {
      StoreTable,
    },
    data () {
      return {
        fetchedBusiness: false,
      }
    },
    computed: {
      ...mapState([
        'businessParentsChilds',
        'user',
        'actionsBusiness',
        'actionsParentBusiness',
      ]),
    },
    watch: {
      async user (user) {
        if (user && !this.fetchedBusiness) {
          await this.getParentChilds(user.businessId || this.actionsactionsParentBusinessBusiness)
          this.fetchedBusiness = true
        }
      },
    },
    async created () {
      if (this.user) {
        // // // // // // // // // // // // // // // // // // // // // // // // // console.log(this.user.businessId || this.actionsBusiness)
        await this.getParentChilds(this.user.businessId || this.actionsParentBusiness)
        this.fetchedBusiness = true
      }
    },
    methods: {
      ...mapActions([
        'getParentChilds',
      ]),
    },
  }
</script>
