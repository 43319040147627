<template>
  <div>
    <br>
    <v-row
      justify="center"
      justify-md="end"
    >
      <v-col
        cols="3"
        md="1"
      >
        <v-fab-transition>
          <v-btn
            color="orange"
            fab
            large
            class="v-btn--example"
            @click="openedCreateDialog = true"
          >
            <v-icon>
              mdi-store-plus
            </v-icon>
          </v-btn>
        </v-fab-transition>
      </v-col>
    </v-row>
    <store-table-top-create-business v-model="openedCreateDialog" />
  </div>
</template>

<script>
  import StoreTableTopCreateBusiness from './StoreTableTopCreateBusiness'

  export default {
    name: 'StoreTableTop',
    components: {
      StoreTableTopCreateBusiness,
    },
    data () {
      return {
        openedCreateDialog: false,
      }
    },
  }
</script>
