<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card
          icon="mdi-store-settings"
          title="Tienda"
          class="px-5 py-3"
          color="orange"
        >
          <v-data-table
            :headers="headers"
            :items="businessParentsChilds.filter(item => item.status === 1)"
            :loading="fetchingParentChilds"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <store-table-top />
            </template>
            <template v-slot:[`item.phone2`]="{ item: business }">
              <span v-if="business.phone2">{{ business.phone2 }}</span>
              <span v-else>No hay teléfono secundario disponible</span>
            </template>
            <template v-slot:[`item.actions`]="{ item: business }">
              <store-table-actions :business="business" />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import StoreTableTop from './StoreTableTop.vue'
  import StoreTableActions from './StoreTableActions.vue'

  export default {
    name: 'StoreTable',
    components: {
      StoreTableTop,
      StoreTableActions,
    },
    data () {
      return {
        headers: [
          {
            text: 'Correlativo',
            value: 'code',
          },
          {
            text: 'Nombre',
            value: 'name',
          },
          {
            text: 'Correo electrónico',
            value: 'email',
          },
          {
            text: 'Rif',
            value: 'rif',
          },
          {
            text: 'Dirección',
            value: 'address',
          },
          {
            text: 'Teléfono',
            value: 'phone1',
          },
          {
            text: 'Teléfono secundario',
            value: 'phone2',
          },
          {
            text: 'Acciones',
            value: 'actions',
          },
        ],
      }
    },
    computed: {
      ...mapState([
        'fetchingParentChilds',
        'businessParentsChilds',
      ]),
    },
  }
</script>
