<template>
  <div>
    <v-btn
      icon
      color="orange"
      class="transparent-button"
      @click="openedEditDialog = true"
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <v-btn
      icon
      color="red"
      class="transparent-button"
      @click="deactiveBusinessAction"
    >
      <v-icon>
        mdi-delete
      </v-icon>
    </v-btn>
    <edit-business-dialog
      v-model="openedEditDialog"
      :business="business"
    />
    <confirm
      v-model="openedRemoveConfirmDialog"
      title="¿Está seguro de querer eliminar esta tienda?"
      text="Por seguridad la tienda será inhabilitada de la aplicación, mas no eliminada"
      @accept="handleRemoveConfirmCallback"
    />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import EditBusinessDialog from './EditBusinessDialog.vue'
  import Confirm from '../../../../components/Confirm.vue'

  export default {
    name: 'StoreTableActions',
    components: {
      EditBusinessDialog,
      Confirm,
    },
    props: {
      business: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        openedEditDialog: false,
        openedRemoveConfirmDialog: false,
      }
    },
    computed: {
      ...mapState([
        'user',
        'deactivingBusiness',
        'actionsParentBusiness',
      ]),
    },
    methods: {
      ...mapActions([
        'getParentChilds',
        'deactiveBusiness',
      ]),
      deactiveBusinessAction () {
        if (this.user) {
          this.openedRemoveConfirmDialog = true
        }
      },
      async handleRemoveConfirmCallback () {
        // // // // // // // // // // // // // // // // // // // // // // // // // // console.log('this.business.id - 1', this.business.id)
        await this.deactiveBusiness(this.business.id)
        await this.getParentChilds(this.user.businessId || this.actionsParentBusiness)
      },
    },
  }
</script>

<style scoped>
  .transparent-button {
    background-color: transparent !important;
  }
</style>
