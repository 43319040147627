<template>
  <v-dialog
    v-model="dialog"
    max-width="490"
  >
    <v-card>
      <v-card-title class="text-h2">
        {{ title }}
      </v-card-title>

      <v-card-text class="text-center grey--text">
        {{ text }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="red darken-1"
          text
          @click="dialog = false"
        >
          {{ cancelText }}
        </v-btn>

        <v-btn
          color="green darken-1"
          text
          @click="accept"
        >
          {{ acceptText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'Confirm',
    props: {
      value: {
        type: Boolean,
        default () {
          return false
        },
      },
      title: {
        type: String,
        default () {
          return ''
        },
      },
      text: {
        type: String,
        default () {
          return ''
        },
      },
      acceptText: {
        type: String,
        default () {
          return 'Aceptar'
        },
      },
      cancelText: {
        type: String,
        default () {
          return 'Cancelar'
        },
      },
    },
    computed: {
      dialog: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        },
      },
    },
    methods: {
      accept () {
        this.dialog = false
        this.$emit('accept')
      },
    },
  }
</script>
